import React, { useContext, useEffect } from 'react';
import styles from './SelectIsAdult.module.css'
import { Context } from '../../context';
import BigButton from '../UI/bigButton/BigButton';


const SelectIsAdult = ({}) => {
    
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
        window.childDoctors = require('../../doctors.json');
        window.childServices = require('../../services.json');
    }  
    
    var childDoctors = window.childDoctors;
    var childServices = window.childServices;



    useEffect(() => {
        if(childDoctors?.length == 0 && childServices?.length == 0){
            setData({...data, isAdult: 'ВЗРОСЛЫЙ'});
        }
    }, [])

    const {data, setData} = useContext(Context);

    const types = ['РЕБЕНОК', 'ВЗРОСЛЫЙ'];

    function selectType(value){
        setData({...data, isAdult: value});
    }    

    if((!childDoctors && !childServices) || (childDoctors?.length == 0 && childServices?.length == 0)){
        return ('');
    }

    return (
        <div className={styles.types}>
            <div className={styles.types__title}>Кто идет на прием?</div>
            <div className={styles.types__container}>
                {types.map((type) => <BigButton key={type} title={type} onClick={() => selectType(type)} active={data.isAdult == type}/>)}
            </div>
        </div>
    );
};

export default SelectIsAdult;