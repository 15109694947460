import './App.css';
import './reset.css'
import './fonts/ApercuPro/stylesheet.css'
import Main from './Main';
import WebApi from './helpers/WebApi';
import { useEffect, useState } from 'react';
import { Context } from './context';

function App() {

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
    window.config = require('./config.json');
  }  

  let config = window.config;

  const [isReady, setIsReady] = useState(false);
  const [data, setData] = useState({serviceType: 0, isAdult: "ВЗРОСЛЫЙ", isOneBranchAndOneType: false, clinicBranchId: null});
  const [branches, setBranches] = useState([]);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    checkBranchAndTypeIsOne();
  }, [])

  function checkBranchAndTypeIsOne(){
    let getBranchesPromise = new Promise((resolve, reject) => {
      let web_api = new WebApi;

      web_api.getClinicBranches((branches) => {
        if (branches != 'error') {
          setBranches(branches);
          resolve(branches);
        } else {
          console.log(branches)
          reject();
        }
      });
    })

    let getTypesPromise = new Promise((resolve, reject) => {
      let web_api = new WebApi;
    
      web_api.getAppointmentTypes((types) => {
        if (types != 'error') {
          setTypes(types);
          resolve(types);
        } else {
          console.log(types)
          reject();
        }
      });
    });

    Promise.all([getBranchesPromise, getTypesPromise])
      .then((res) => {
        const [branches, types] = res;
        if(branches.length == 1 && types.length == 1){
          setData({...data, serviceType: types[0].key, clinicBranchId: branches[0].id, clinicBranchName: branches[0].fullName, isOneBranchAndOneType: true})
        }
        setIsReady(true);
    })
  }  

  return (
    <>
      <style>{`
          :root {
            --primary: `+ config.color_primary + `;
            --secondary: `+ config.color_secondary + `;
            --doctor_time: `+ config.color_doctor_time + `;
            --gray: `+ config.color_gray + `;
            --text: `+ config.color_default_text + `;
          }
          `}
      </style>

      <Context.Provider value={{
        data,
        setData,
        branches,
        types
      }}>
        {isReady && <Main/>}
      </Context.Provider>
      
    </>
  );
}

export default App;