import React, { useContext, useState } from 'react';
import styles from './Header.module.css'
import Button from '../UI/button/Button';
import SVGIcon from '../UI/SVGIcons/SVGIcon';
import { Context } from '../../context';

function Header() {

    let config = window.config;
    const {data} = useContext(Context);

    const [color, setColor] = useState(false);

    return (
        <div className={styles.header}>
            <div className={styles.header__container}>        
                <div className={styles.header__logo}>            
                    <a href={config.site_url}><img src={config.logo} alt=""/></a>
                </div>

                {data.isMobile ?
                        <div className={styles.header__right}>
                            <div className={styles['header__right-info']}>
                                <a href={`tel:${config.phone}`} className={styles['header__right-info__phone']}>
                                    <SVGIcon name={'phone'}/>
                                </a>
                            </div>
                            {config.lk_url && 
                                <a href={`${config.lk_url}`}>
                                    <SVGIcon name={'person'} color={config.color_primary}/>
                                </a>                        
                            }
                        </div>
                    :
                    <div className={styles.header__right}>
                        <div className={styles['header__right-info']}>
                            <a href={`tel:${config.phone}`} className={styles['header__right-info__phone']} dangerouslySetInnerHTML={{ __html: config.phone }}></a>
                            {config.work_time &&
                                <div dangerouslySetInnerHTML={{ __html: config.work_time }}></div>
                            }
                        </div>
                        {config.lk_url && 
                            <a href={`${config.lk_url}`}>

                                <Button 
                                radius={20}                                
                                active={false}
                                onMouseEnter={() => setColor(true)}
                                onMouseLeave={() => setColor(false)}
                                >
                                    <div style={{display: 'flex', marginLeft: '10px', alignItems: 'center'}}>
                                        <SVGIcon name={'person'} color={color ? config.color_primary : config.color_secondary}/>
                                    </div>
                                    <div className={styles.header__button_text}>ВХОД ЛК</div>
                                </Button>
                            </a>                        
                        }
                        
                    </div>
                }
            </div>
        </div>
    )
}

export default Header;