import React from 'react';
import styles from './Specialization.module.css'

const Specialization = ({name, onClick}) => {
    return (
        <div className={styles.specialization} onClick={onClick}>
            {name}
        </div>
    );
};

export default Specialization;