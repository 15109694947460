import React, { useContext } from 'react';
import styles from './AppointmentTypes.module.css'
import { Context } from '../../context';
import BigButton from '../UI/bigButton/BigButton';


const AppointmentTypes = ({}) => {

    const {data, setData, types} = useContext(Context);

    function SelectType(value){
        setData({...data, serviceType: value});
    }    

    return (
        <div className={styles.types}>
            <div className={styles.types__title}>Выберите тип приема</div>
            <div className={styles.types__container}>
                {types && types.length > 0 && types.map((type) => 
                    <BigButton key={type.key} title={type.value.toUpperCase()} onClick={() => SelectType(type.key)} active={data.serviceType == type.key}/>                
                )}             
            </div>
        </div>
    );
};

export default AppointmentTypes;