import React, { useContext, useEffect, useState } from 'react';
import styles from './ServicesSearch.module.css';
import { Context } from '../../../context';
import WebApi from '../../../helpers/WebApi';

const ServicesSearch = ({searchText}) => {
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
  //   window.childServices = require('../../../services.json');
  // }  

  let childServices = window.childServices;

  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const {data, setData} = useContext(Context);
  
  function selectService(id, name, price){
    setData({...data, serviceId: id, serviceName: name, servicePrice: price});
  }   

  useEffect(() => {    

    let web_api = new WebApi;

    web_api.getServices({...data, userId: null}, (res) => {
      if (res != 'error') {
        setServices(res.filter((service) => service.price != null));
      } else {
        console.log(res)
      }
    });    
  }, [])

  useEffect(() => {
    if(filteredServices.length > 0 || searchText.length > 0){
      servicesFilter();
    }
  }, [data])


  useEffect(() => {
    if(searchText.length > 0){
      servicesFilter();
    } else {
      setFilteredServices([])
    }
  }, [searchText, services])

  function servicesFilter(){
      setFilteredServices(
        services.filter((service) => {

          if(data.isAdult == 'РЕБЕНОК'){
            if(!childServices.some((childService) => childService == service?.code)){
                return false;
            }
          }

          if(service?.name?.toLowerCase().includes(searchText)){
            return true;
          }
        })
      )
  }   

  return (
    <div>
      
      {filteredServices.length != 0 ?
        <div className={styles.services}>
            <div className={styles.services__text}>Услуги</div>
            <div className={styles.services__container}>
                {filteredServices.length > 0 && filteredServices.map((service) =>
                  <div key={service.id} className={styles.service} onClick={() => selectService(service.id, service.name, service.price)}>{service.name}</div>
                )}
            </div>
        </div>
        :
        ''
      }
    </div>
  );
};

export default ServicesSearch;