import React, { useContext } from 'react';
import styles from './Tab.module.css'
import { Context } from '../../../context';

const Tab = ({title, number, active, onClick, accessible, visible}) => {

    
    const {data} = useContext(Context);
    
    if(visible == true) {
        return '';
    }
    const isActive = () => {
        return active && styles.active
    }

    const isAccessible = () => {
        return accessible && styles.accessible
    }

    return data.isMobile && !active ? ''
    : (
        <div className={styles.tab + ' ' + isActive() + ' ' + isAccessible()} onClick={() => accessible && onClick()}>
            <div className={styles.tab__container}>
                {accessible && !active 
                ? <div className={styles.tab__number + ' ' + isActive() + ' ' + isAccessible()}>✓</div>
                : <div className={styles.tab__number + ' ' + isActive()}>{number}</div>}
                
                <div className={styles.tab__title}>{title}</div>
            </div>
        </div>
    );
};

export default Tab;

