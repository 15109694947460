import React, { forwardRef, useContext, useEffect, useState } from 'react';
import styles from './SelectDoctor.module.css'
import DoctorTime from './doctorTime/DoctorTime';
import Button from '../UI/button/Button';
import { Context } from '../../context';
import WebApi from '../../helpers/WebApi';
import moment from 'moment';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css'
import ru from 'date-fns/locale/ru';
import './CustomDatePicker.css';
import SVGIcon from '../UI/SVGIcons/SVGIcon';
import { Oval } from 'react-loader-spinner'

const CustomDateInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <div
        className="custom-date-input__container"
        onClick={onClick}>
        <SVGIcon name={'calendar'} />
        <input
            value={value}
            className="custom-date-input__input"
            onChange={onChange}
            ref={ref}
            readOnly={true}
        ></input>
    </div>
));


const SelectDoctor = () => {

    const { data, setData } = useContext(Context);
    const [loader, setLoader] = useState(true);
    const [doctors, setDoctors] = useState([]);
    const [dates, setDates] = useState([]);
    const [dayPart, setDayPart] = useState(null);
    const [selectedService, setSelectedService] = useState(data.serviceId ?
        {
            value: data.serviceId,
            label: data.serviceName,
            price: data.servicePrice
        } : null
    );

    const [selectedDay, setSelectedDay] = useState(null);

    let config = window.config;

    registerLocale('ru-RU', ru);

    let [start_period, setStartPeriod] = useState({
        start_date: moment().startOf('isoDay').toISOString(true),
        end_date: moment().add(30, 'days').toISOString(true)
    });

    useEffect(() => {
        setLoader(true);
        setDoctorsMoreThenNull(false);

        if (!data.userId) {
            setData({ ...data, serviceId: selectedService?.value, serviceName: selectedService?.label, servicePrice: selectedService?.price })
        }

        let web_api = new WebApi;

        web_api.getDoctorsFreeTime({ ...data, fromDate: start_period.start_date, tillDate: start_period.end_date, serviceId: selectedService?.value }, (res) => {
            if (res != 'error') {
                let newRes = [];

                for (let i = 0; i < res.length; i++) {
                    if (res[i].userId) {
                        for (let j = i + 1; j < res.length; j++) {
                            if (res[i].userId == res[j].userId) {
                                res[j].userId = null;
                            }

                        }
                        newRes.push(res[i]);
                    }
                }

                setDoctors(newRes);
                setLoader(false);
            } else {
                console.log(res)
            }
        });

    }, [start_period, selectedService])

    useEffect(() => {

        let set = new Set();

        for (const doctor of doctors) {
            for (const time of doctor.freeTimes) {
                if (time.isFreeDocTime) {
                    set.add(time.date.split('T')[0]);
                }
            }
        }

        setDates(Array.from(set, (date) => new Date(date)).sort(function (a, b) {
            return new Date(a) - new Date(b);
        }));

        countingDoctorsWithTime();
    }, [doctors])

    useEffect(() => {
        if (dates.length > 0 && !data.selectedDay) {
            setSelectedDay(dates[0]);
        }
    }, [dates])

    function changeMonth(newDate) {
        setStartPeriod({
            start_date: moment(newDate).startOf('month').format('YYYY-MM-DD'),
            end_date: moment(newDate).endOf('month').format('YYYY-MM-DD')
        })
    }

    function handleServiceSelect(service) {
        setSelectedService(service);
        //запрос врачей?
    }

    const [services, setServices] = useState([]);

    useEffect(() => {
        if (data.selectedDay) {
            setSelectedDay(new Date(data.selectedDay));
        } else {
            setSelectedDay(new Date());
        }

        let web_api = new WebApi;

        web_api.getServices(data, (res) => {
            if (res != 'error') {
                setServices(res.filter((service) => service.price != null));
            } else {
                console.log(res)
            }
        });
    }, [])

    const selectStyles = {
        control: (styles) => ({
            ...styles,
            width: '218px',
            height: '33px',
            'min-height': '26px',

            borderColor: !selectedService ? config.color_doctor_time : config.color_primary,
            backgroundColor: !selectedService ? config.color_doctor_time + '96' : 'white',

            borderRadius: '10px',
            borderWidth: '1px',
            ':hover': {
                borderColor: config.color_primary,
                backgroundColor: config.color_primary + '20',
            },
            boxShadow: '0 0 0 0',
            '#react-select-3-placeholder': {
                color: config.color_primary
            }
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            padding: '0 6px',
            marginTop: '0',
            'font-size': '16px',
        }),
        option: (styles) => ({
            ...styles,
        }),
        dropdownIndicator: base => ({
            ...base,
            color: config.color_doctor_time
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
        }),
        placeholder: base => ({
            ...base,
            color: config.color_gray
        })
    }


    const [doctorsMoreThenNull, setDoctorsMoreThenNull] = useState(false);

    function countingDoctorsWithTime() {

        for (const doctor of doctors) {

            let times = doctor.freeTimes.filter(freeTime => freeTime.date.split('T')[0] === moment(selectedDay).toISOString(true).split('T')[0]);

            if (dayPart != null) {
                times = times.filter((time) => {
                    const hour = Number(time.timeBegin.slice(0, -6).split(':')[0]);

                    if (dayPart == 0 && hour < 12) {
                        return true;
                    }
                    if (dayPart == 1 && hour >= 12 && hour < 18) {
                        return true;
                    }
                    if (dayPart == 2 && hour >= 18) {
                        return true;
                    }

                    return false
                });
            }

            if (!times.some((time) => time.isFreeDocTime)) {
                setDoctorsMoreThenNull(false);
            } else {
                setDoctorsMoreThenNull(true);
                return;
            }
        }
    }

    useEffect(() => {
        countingDoctorsWithTime();
    }, [selectedDay, dayPart])


    return (
        <div className={styles.doctorSelect}>
            <div className={styles.title + ' ' + styles.title_mobile}>
                {data.userId ? 'Запись к врачу'
                    : data.serviceName
                }
            </div>
            <div className={styles.container}>

                <div>

                    <div className={styles.parameters}>
                        <div className={styles.select_date}>
                            <div className={styles.select_text}>Выберите дату</div>
                            <ReactDatePicker
                                popperPlacement='bottom-start'
                                selected={selectedDay}
                                onChange={(date) => setSelectedDay(date)}
                                onMonthChange={changeMonth}
                                dateFormat={'dd.MM.yy'}
                                minDate={new Date()}
                                locale={ru}
                                disabledKeyboardNavigation
                                forceShowMonthNavigation={true}
                                includeDates={dates}
                                customInput={<CustomDateInput />}
                            />
                        </div>


                        <div className={styles.dayParts}>
                            {['Утро', 'День', 'Вечер'].map((part, i) =>
                                <Button
                                    key={part}
                                    onClick={() => setDayPart(prev => prev == i ? null : i)}
                                    active={dayPart == null || dayPart != i}
                                    radius={20}>
                                    {part}
                                </Button>
                            )}
                        </div>

                        <div>
                            <div className={styles.select_text}>Выберите услугу</div>
                            <Select
                                placeholder='Выберите услугу'
                                options={services.map((service) => ({ value: service.id, label: service.name, price: service.price }))}
                                value={selectedService}
                                styles={selectStyles}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                onChange={handleServiceSelect}
                                className="react-select-container"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </div>
                </div>


                <div className={styles.title_doctors}>

                    {doctorsMoreThenNull
                        ? <div className={styles.title} style={{ marginTop: 0 }}>Вас готовы принять</div>
                        : <div className={styles.title}>{loader ? '' : 'Нет свободного времени'}</div>
                    }

                    <div id='doctors' className={styles.doctors}>
                        {loader ?
                            <div className={styles.loader_container}>
                                <div>
                                    <div className={styles.title}>Идет загрузка...</div>
                                    <div className={styles.loader}>
                                        <Oval
                                            visible={true}
                                            height="40"
                                            width="40"
                                            color={config.color_primary}
                                            secondaryColor={config.color_secondary}
                                            ariaLabel="oval-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                        />
                                    </div>

                                </div>

                            </div>

                            : doctors.map((doctor) =>
                                <DoctorTime key={doctor.id} doctor={doctor} selectedDay={selectedDay} dayPart={dayPart} selectedService={selectedService} />
                            )}
                    </div>
                </div>


            </div>
        </div>
    );
};

export default SelectDoctor;