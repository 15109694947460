import React, { useEffect, useState } from 'react';
import styles from '../../branches/Branches.module.css'
import Branch from '../../branches/branch/Branch';
import WebApi from '../../../helpers/WebApi';

const ListBranches = ({onClick, selectedBranch}) => {

    const [branches, setBranches] = useState([]);

    useEffect(() => {
        let web_api = new WebApi;
    
        web_api.getClinicBranches((res) => {
          if (res != 'error') {
            setBranches(res);
          } else {
            console.log(res)
          }
        });
    }, [])


    return (
        <div className={styles.branches}>
            <div className={styles.branch__title}>Выберите филиал</div>
            <div className={styles.branches__container}>
                {branches && branches.length > 0 && branches.map((branch) => 
                    <Branch 
                        key={branch.id} 
                        name={branch.fullName} 
                        address={branch.addressLocation}
                        onClick={() => onClick(branch)}
                        active={ selectedBranch?.id == branch.id}/>
                )}                
            </div>
        </div>
    );
};

export default ListBranches;