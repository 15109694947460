import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import styles from './PatientInfo.module.css'
import { Context } from '../../context';
import Button from '../UI/button/Button';
import WebApi from '../../helpers/WebApi';
import ReactInputMask from 'react-input-mask';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import SVGIcon from '../UI/SVGIcons/SVGIcon';
import { ru } from 'date-fns/locale/ru';
import moment from 'moment';
import Review from './Review';


// const CustomDateInput = forwardRef(({ value, onClick, onChange }, ref) => (
//     <div 
//         className={styles['custom-date-input__container']}
//         onClick={onClick}
//     >
//         <SVGIcon name={'calendar'}/>
//         <input
//             id='custom-date'
//             value={value}
//             className="custom-date-input__input"            
//             onChange={onChange}
//             ref={ref}
//             placeholder='Дата рождения'
//         ></input>
//     </div>
// ));


const PatientInfo = () => {
    let config = window.config;

    const {data, setData} = useContext(Context);

    registerLocale('ru-RU', ru);

    const [selectedDay, setSelectedDay] = useState(null);  

    const [isScheduled, setIsScheduled] = useState(false);  

    const [patientData, setPatientData] = useState(
        {
            lastName: '',
            firstName: '',
            phone: '',
        }
    )

    function makeAppointment(){
        let leadName = patientData.lastName + ' ' + patientData.firstName + ' ' + patientData.midName;
        let web_api = new WebApi;
        web_api.sendPostSchedule({...data, leadName, leadPhone: patientData.phone, comment: selectedDay != null ? `Дата рождения: ${new Date(selectedDay).toLocaleDateString()}` : '' }, (res) => {
            if(res == true){
                setReviewVisible(true);
                setIsScheduled(true);
            }
        });
    }

    const [reviewVisible, setReviewVisible] = useState(false);  

    return !isScheduled ?
        (<div className={styles.container}>
            <div className={styles.title}>Введите данные пациента</div>
            <div className={styles.inputs}>
                <div className={styles.required}></div>
                <input 
                    className={!patientData.lastName ? styles.input_error : ''}
                    type="text" 
                    id='NAME_LAST'
                    placeholder='Фамилия' 
                    required
                    autocomplete="on"
                    value={patientData.lastName} 
                    onChange={(e) => setPatientData({...patientData, lastName: e.target.value})}
                />
                <div className={styles.required}></div>
                <input 
                    className={(!patientData.firstName ? styles.input_error : '')}
                    type="text" 
                    id="NAME_FIRST"
                    placeholder='Имя'
                    autocomplete="on"
                    value={patientData.firstName} 
                    onChange={(e) => setPatientData({...patientData, firstName: e.target.value})}
                />
                <input 
                    type="text" 
                    id='NAME_MIDDLE'
                    placeholder='Отчество'
                    autocomplete="on"
                    value={patientData.midName} 
                    onChange={(e) => setPatientData({...patientData, midName: e.target.value})}
                />

                <div className={styles.required}></div>

                <ReactInputMask 
                    className={(patientData.phone.length < 12 ? styles.input_error : '')}
                    mask="+79999999999" 
                    maskChar="" 
                    name={'phone'} 
                    type={'text'} 
                    autocomplete="on"
                    placeholder={'Телефон'} 
                    onChange={(e) => setPatientData({...patientData, phone: e.target.value})}>
                </ReactInputMask>


                <ReactDatePicker
                    selected={selectedDay} 
                    onChange={(e) => {
                        setSelectedDay(e)
                    }}
                    dateFormat={'dd.MM.yy'}
                    locale={ru}
                    // customInput={<input/>}
                    dropdownMode='select'
                    placeholderText='Дата рождения'
                    showYearDropdown
                    showMonthDropdown    
                    maxDate={new Date()}     
                    autoComplete="off"       
                    className=''    
                />       

                <div className={styles.info}>
                    {data.serviceName ? data.serviceName + ',' : ''} {data.serviceName && <br/>}<div className={styles.upper}>{moment(data.date).format('dd')}</div> {new Date(data.date).toLocaleDateString()}, {data.timeBegin.slice(0, -3)}<br/>
                    {data.servicePrice ? `Стоимость - ${data.servicePrice} ${config.currency}` : ''}
                </div>
                
            <div className={styles.button_container}>
                <Button 
                    onClick={() => {
                        makeAppointment();
                    }} 
                    active={false} 
                    radius={15}
                    disabled={!patientData.lastName || !patientData.firstName || patientData.phone.length != 12}
                >
                    <div className={styles.button}>Записать на прием</div>
                </Button>
            </div>
            </div>

            <div className={styles.agreements}>
                Нажимая "Записаться на прием" вы соглашаетесь с <a href={config.link_policy}>условиями обработки персональных данных</a>
            </div>
        </div>)
        :
        (
            <div>
                {config.showReview && <Review visible={reviewVisible} onClose={() => setReviewVisible(false)}/>}
                
                

                <div className={styles.finish_container}>
                    <div className={styles.finish_title}> {patientData.lastName + ' ' + patientData.firstName}, Вы записаны</div>

                    <div className={styles.doctor_container}> 
                        {data.serviceName ? data.serviceName : ''}   
                        <div className={styles.doctor_container__header}>
                            <div className={styles.doctor_container__header_photo}>
                                {data.doctor.photoUrl != null ?
                                    (<img src={data.doctor.photoUrl}/>) :
                                    (<img src={require('../../img/doctor.png')}/>)
                                }
                            </div>
                            <div className={styles.doctor_container__header_info}>
                                <div className={styles.doctor_container__header_info__username}>{data.doctor.userName}</div>
                                <div  className={styles.doctor_container__header_info__specializations}>
                                    {data.doctor.specializations.map((e, i) => {
                                        return `${e.name}${(data.doctor.specializations.length > 1 && i != Number(data.doctor.specializations.length - 1)) ?  ', ' : ''}`;
                                    })}
                                </div>                            
                            </div>
                            
                        </div>
                        <div className={styles.finish_info}><div className={styles.upper}>{moment(data.date).format('dd')}</div> {new Date(data.date).toLocaleDateString()}, {data.timeBegin.slice(0, -3)} в {data.clinicBranchName}</div>
                        {config.show_services_price && <div className={styles.finish_info}>{data.servicePrice ? `Стоимость - ${data.servicePrice} ${config.currency}` : ''}</div>}
                    </div>


                    <div className={styles.finish_footer}>Для отмены или изменения записи обратитесь по телефону {config.phone} <br/> {config.textAfterAppoint}</div>

                    
                </div>
            </div>
            
        )
    ;
};

export default PatientInfo;