import React, { useState } from 'react';
import styles from './Review.module.css'
import Button from '../UI/button/Button';

const Review = ({onClose, visible}) => {

    const [rating, setRating] = useState(null);  
    const [comment, setComment] = useState('');  

    const scriptURL = 'https://script.google.com/macros/s/AKfycbyn5JltEY9NEH6tJF-4rxWpfp8m6OLBjukos_hECoEBlwf57qRmQMf0nIhrt7yySjc6yw/exec';

    function sendReview(){
        onClose();
        fetch(scriptURL, {
            method: 'POST',
            body: JSON.stringify({rating: rating, comment: comment, windowWidth: window.innerWidth, client: document.location.href}),
            headers: {
              'Content-Type': 'text/plain;charset=utf-8',
            },
          })
        .then((response) => response.text())
        .then((result) => {
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };


    return visible &&
        <div className={styles.modal}  onClick={onClose}>
            <div className={styles.modal__container} onClick={(e) => e.stopPropagation()}>

                <div className={styles.text1}>Оцените работу сервиса онлайн записи</div>

                <div className={styles.rating}>
                    {[5,4,3,2,1].map((rate) => {
                        return <><input type="radio" name="rating" value={rate} id={rate} /><label onClick={() => setRating(rate)} htmlFor={rate}>☆</label></>
                    })}
                </div>

                <div className={styles.text2}>Расскажите, что вам понравилось или не понравилось в процессе самостоятельной записи онлайн</div>
                        
                <div className={styles.textarea__container}>
                    <textarea 
                        rows={6}
                        placeholder="Ваш комментарий" 
                        value={comment} 
                        onChange={(e) => setComment(e.target.value)}
                    />
                </div>

                <div className={styles.text3}>Это не обязательно, но поможет нам сделать запись еще лучше</div>

                <div className={styles.button_container}>
                    <Button 
                        onClick={sendReview} 
                        active={false} 
                        radius={15}
                        disabled={!rating}
                    >
                        <div className={styles.button}>Отправить оценку</div>
                    </Button>
                </div>
                
            </div>
        </div>
    
};

export default Review;