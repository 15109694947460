import React, { useContext } from 'react';
import { useEffect, useState } from 'react';

import Header from './components/header/Header';
import Tabs from './components/tabs/Tabs';
import Branches from './components/branches/Branches';
import AppointmentTypes from './components/appointmentTypes/AppointmentTypes';
import SelectIsAdult from './components/selectIsAdult/SelectIsAdult';
import DoctorAndServiceSearch from './components/doctorAndServiceSearch/DoctorAndServiceSearch';
import SelectDoctor from './components/selectDoctor/SelectDoctor';
import PatientInfo from './components/patientInfo/PatientInfo';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LinkListDoctors from './components/linkList/LinkListDoctors';
import LinkListServices from './components/linkList/LinkListServices';
import WebApi from './helpers/WebApi';
import { Context } from './context';

const Main = () => {
    
    let config = window.config;
    
    const linkGet = document.location.search;
    const linkGetParams = new URLSearchParams(linkGet);
    const doctorId = linkGetParams.get("doctor");
    const serviceId = linkGetParams.get("service");
    const serviceType = linkGetParams.get("type");
    const linkBranchId = linkGetParams.get("branch");

    const {data, setData} = useContext(Context);

    const [step, setStep] = useState(1);
    const [steps, setSteps] = useState([
    {
        title: 'ФИЛИАЛ И ТИП ПРИЕМА',
        components: [
        <AppointmentTypes/>,
        <Branches/>
        ],
        accessible: true,
        available: true,
    },
    {
        title: 'ПАРАМЕТРЫ ПОИСКА',
        components: [
        <SelectIsAdult/>,
        <DoctorAndServiceSearch/>
        ],
        accessible: false,
        available: true
    },
    {
        title: 'ВЫБОР ВРАЧА',
        components: [
        <SelectDoctor/>
        ],
        accessible: false,
        available: true
    },
    {
        title: 'ДАННЫЕ ПАЦИЕНТА',
        components: [
        <PatientInfo/>
        ],
        accessible: false,
        available: true
    },
    ])

    const handleResize = () => {
    if (window.innerWidth < 720 && !data.isMobile) {
        setData({...data, isMobile: true})
    } else if(window.innerWidth > 720 && data.isMobile) {
        setData({...data, isMobile: false})
    }

    //Колонки на шаге поиска врача
    if (window.innerWidth < 1050 && data.columnsCount != 2) {
        setData({...data, columnsCount: 2})
    } else if(window.innerWidth > 1050 && data.columnsCount != 3) {
        setData({...data, columnsCount: 3})
    }
    }
    
    // create an event listener
    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener('resize', handleResize);
    })

    useEffect(() => {

        if((data.serviceType || data.serviceType == 0) && data.clinicBranchId){

            if(!steps[1].accessible){

                setStep(2);

                setSteps(prev => prev.map((obj, i) => {
                    if(i == 1){
                        obj.accessible = true;
                    }

                    return obj;
                    })
                );
            }
        }

        if(data.userId || data.serviceId) {
            setStep(3);
            setSteps(prev => prev.map((obj, i) => {
                if(i == 2){
                    obj.accessible = true;
                }
                return obj;
                })
            );
        } 

    if(data.timeBegin && data.timeEnd && data.date && data.doctorSpecializationId && (data.serviceType || data.serviceType == 0)  && data.clinicBranchId){
        setStep(4)
    } else {
        setSteps(prev => prev.map((obj, i) => {
            if(i == 3){
                obj.accessible = false;
            }
            return obj;
        }));
    }

    }, [data])

    window.onpopstate = function(event) {
        if(step > 1){
            setStep(step-1);
        }
    };

    useEffect(() => {
        if(step == 1) { //если вернулись на первый шаг - все обнуляем
            [1,2,3,4].map(() => window.history.pushState({}, ''))
            
            if(data.isOneBranchAndOneType){
                setData({
                    serviceType: data.serviceType, 
                    isAdult: data.isAdult, 
                    isOneBranchAndOneType: data.isOneBranchAndOneType, 
                    clinicBranchId: data.clinicBranchId, 
                    clinicBranchName: data.clinicBranchName,
                    userId: null, 
                    serviceId: null, 
                    serviceName: null, 
                    servicePrice: null, 
                    timeBegin: null, 
                    timeEnd: null, 
                    date: null, 
                    doctorSpecializationId: null
                });
            } else {
                setData({serviceType: data.serviceType, isAdult: data.isAdult, isOneBranchAndOneType: data.isOneBranchAndOneType});
            }

            setSteps(prev => prev.map((obj, i) => {
                if(i != 0){
                    obj.accessible = false;
                }
                return obj;
            }))
        } else if(step < 3) { //отключаем доступ к третьему шагу
            setData({...data, userId: null, serviceId: null, serviceName: null, servicePrice: null, timeBegin: null, timeEnd: null, date: null, doctorSpecializationId: null});
            setSteps(prev => prev.map((obj, i) => {
            if(i == 2 || i == 3){
                obj.accessible = false;
            }
            return obj;
            }))
        } else if(step == 3) {
            setData({...data, timeBegin: null, timeEnd: null, date: null});

            setSteps(prev => prev.map((obj, i) => {
            if(i == 3){
                obj.accessible = false;
            }
            return obj;
            }))
        }
    }, [step])


    useEffect(() => {

        [1,2,3,4].map(() => window.history.pushState({}, ''))
        
        if((serviceType || serviceType == 0) && linkBranchId){

            if(doctorId){
                setData({...data, serviceType: serviceType, clinicBranchId: linkBranchId, userId: doctorId});
            }

            if(serviceId){
            let web_api = new WebApi;
        
            web_api.getServices({clinicBranchId: linkBranchId, serviceType: serviceType}, (res) => {
                if (res != 'error') {
                    let findedService = res.find((service) => service.id == serviceId);
                    setData({...data, serviceType: serviceType, clinicBranchId: linkBranchId, serviceId: findedService.id, serviceName: findedService.name, servicePrice: findedService.price});
                } else {
                    console.log(res)
                }
            });
            }
        }
    }, [])

    return (
        <BrowserRouter basename={(config.batch) ? config.batch : '/'}>        
          <div className="App">

            <Routes>
              <Route exact path={'/'} element={
                (<>
                  <Header/>
                  <Tabs steps={steps} activeStep={step} changeStep={setStep}/>
                  
                  {steps[step-1].components}
                </>)
              } />
              <Route path={'/' + config.doctor_link} element={<LinkListDoctors/>} />
              <Route path={'/' + config.service_link} element={<LinkListServices/>} />
            </Routes>            
          </div>
      </BrowserRouter>
    );
};

export default Main;