import axios from 'axios';
import Session from '../helpers/Session';

const getDataHeaders = (token) => {
    return {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }    
}

export function UpdateToken ({request, handler}) {

    let data = {
        "name": window.config.name,
        "password": window.config.password
    }
  
    // Функци исполняемая после ответа от сервера
    function feedback(response) {  
        if (response == 'error') {
            return
        }

        let session = new Session;
        session.createSession('token', response.data);  

        request.headers = getDataHeaders(response.data);

        if(request){
            axios(request)
                .then(handler)
                .catch((error) => {
                    console.log(error)
                });
        }
    }  

    let web_api = new WebApi;
    web_api.auth(data, feedback)
    return true;
}

export default class WebApi {

    constructor() {
        let session = new Session;
        this.token = session.getSession('token');
        this.protocol = window.location.protocol;
        this.config = window.config;
        this.href = this.protocol + '//' + this.config.api;
    }

    // POST запросы

    // Запрос на авторизацию

    auth(data, feedback) {

        return axios({
            method: 'POST',
            url: this.href + '/OnlineAppointment/Auth',
            timeout: 5000,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Cache-Control': 'no-cache',
            },
            data: JSON.stringify(data),
        })
            .then(function (response) {
                feedback(response);
                return true
            })
            .catch(function (error) {
                feedback('error');
            });
    }

    // Запрос на запись на прием

    sendPostSchedule(data, PostSchedule) {
        let token = this.token;

        const returnData = (response) => {
            PostSchedule(true);
        }
        
        axios({
            method: 'POST',
            url: this.href + '/OnlineAppointment/v3/PostSchedule',
            headers: getDataHeaders(token),
            data: JSON.stringify(data),
        })
            .then(returnData)
            .catch((error) => {
                if(error.response?.status === 401){
                    UpdateToken({request: error.config, handler: returnData})
                } else {                    
                    alert('Возникла ошибка, попробуйте позже');
                }   
            });            
    }

    // Получение списка типов приёма
    getAppointmentTypes(AppointmentTypes) {
        let token = this.token;

        const returnData = (response) => {
            let json_res = response.request.response;
            let res = JSON.parse(json_res);
            AppointmentTypes(res);
        }
        

        axios({
            method: 'POST',
            url: this.href + '/OnlineAppointment/v3/GetServiceTypes',
            headers: getDataHeaders(token),
        })
            .then(returnData)
            .catch((error) => {
                if(error.response?.status === 401){
                    UpdateToken({request: error.config, handler: returnData})
                };            
            });        
    }

    // Получение списка филиалов

    getClinicBranches(ClinicBranches) {
        let token = this.token;

        const returnData = (response) => {
            let json_res = response.request.response;
            let res = JSON.parse(json_res);
            ClinicBranches(res);
        }

        axios({
            method: 'POST',
            url: this.href + '/OnlineAppointment/v3/GetClinicBranches',
            headers:  getDataHeaders(token),
        })
            .then(returnData)
            .catch((error) => {
                if(error.response.status === 401){
                    UpdateToken({request: error.config, handler: returnData})
                };
            });

    }    

    // Получение списка доступных онлайн-записей по заданному филиалу и типу записи

    getServices(data, OnlineServices) {
        let token = this.token;

        const returnData = (response) => {
            let json_res = response.request.response;
            let res = JSON.parse(json_res);
            OnlineServices(res);
        }

        axios({
            method: 'POST',
            url: this.href + '/OnlineAppointment/v3/GetServices',
            data: data,
            headers:  getDataHeaders(token),
        })
            .then(returnData)
            .catch((error) => {
                if(error.response.status === 401){
                    UpdateToken({request: error.config, handler: returnData})                    
                };     
            });
    }

    //Получение одной услуги {
    //   "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //   "type": 0,
    //   "clinicBranchId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    // }
    getOnlineService(data, OnlineServices) {
        let token = this.token;

        axios({
            method: 'POST',
            url: this.href + '/Price',
            data: data,
            headers:  getDataHeaders(token),
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                OnlineServices(res);
            })
            .catch(function (error) {
                let session = new Session;
                session.createSession('token', undefined);
                OnlineServices('error');
            });

    }

    getDoctors(data, allDoctors) {
        let token = this.token;

        const returnData = (response) => {
            let json_res = response.request.response;
            let res = JSON.parse(json_res);
            allDoctors(res);
        }

        axios({
            method: 'POST',
            url: this.href + '/OnlineAppointment/v3/GetDoctors',
            data: data,
            headers: getDataHeaders(token),
        })
            .then(returnData)
            .catch((error) => {
                if(error.response.status === 401){
                    UpdateToken({request: error.config, handler: returnData})
                }; 
            });
    }

    // Получение коллекции врачей, у которых есть свободные окна для записи по переданным параметрам

    getDoctorsFreeTime(data, DoctorsFreeTime) {
        let token = this.token;

        const returnData = (response) => {
            let json_res = response.request.response;
            let res = JSON.parse(json_res);
            DoctorsFreeTime(res);
        }

        axios({
            method: 'POST',
            url: this.href + '/OnlineAppointment/v3/GetDoctorsFreeTime',
            data: data,
            headers: getDataHeaders(token),
        })
            .then(returnData)
            .catch((error) => {
                if(error?.response?.status === 401){
                    UpdateToken({request: error.config, handler: returnData})
                }; 
            });
    }

    // Получение всех специализаций врачей

    getSpecializationsDoctors(data, SpecializationsDoctors) {
        let token = this.token;

        const returnData = (response) => {
            let json_res = response.request.response;
            let res = JSON.parse(json_res);
            SpecializationsDoctors(res);
        }

        axios({
            method: 'POST',
            url: this.href + '/OnlineAppointment/v3/GetSpecializations',
            data: data,
            headers: getDataHeaders(token),
        })
            .then(returnData)
            .catch((error) => {
                if(error.response.status === 401){
                    UpdateToken({request: error.config, handler: returnData})
                };  
            });

    }

    // Получение списка всех врачей

    getAllDoctors(allDoctors) {
        let token = this.token;

        axios({
            method: 'GET',
            url: this.href + '/OnlineAppointment/v3/GetAllDoctors',
            headers:  getDataHeaders(token)
        })
            .then(function (response) {

                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                allDoctors(res)

            })
            .catch(function (error) {
                let session = new Session;
                session.createSession('token', undefined);
                allDoctors('error')
            });
    }

    // Получение филиалов врачей

    getClinicBranchesByDoctor(data, ClinicBranchesByDoctor) {
        let token = this.token;

        axios({
            method: 'POST',
            url: this.href + '/OnlineAppointment/v3/GetClinicBranchesByDoctor',
            data: data,
            headers: {
                'Content-Type': 'application/json-patch+json',
                'accept': 'text/plain',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(function (response) {
                let json_res = response.request.response;
                let res = JSON.parse(json_res);
                ClinicBranchesByDoctor(res);
            })
            .catch(function (error) {
                let session = new Session;
                session.createSession('token', undefined);
                ClinicBranchesByDoctor('error');
            });
    }
}