import React, { useEffect, useState } from 'react';
import styles from './Specializations.module.css'
import Specialization from './specialization/Specialization';

const Specializations = ({setSearchText, isActive, doctors}) => {

  const [specializations, setSpecializations] = useState([]);

  useEffect(() => {    
    let map = new Map();
    
    doctors.forEach(doctor => {
      doctor.specializations.forEach(specialization => {
        if(!map.has(specialization.id)) {
          map.set(specialization.id, specialization);
        }
      })
    });

    setSpecializations(Array.from(map.values()));
  }, [doctors])

  return isActive &&
    <div className={styles.specializations}>
      {specializations.map((specialization) => <Specialization key={specialization.name} name={specialization.name} onClick={() => setSearchText(specialization.name)}/>)}
    </div>
};

export default Specializations;