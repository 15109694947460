import React from 'react';
// import {ReactComponent as Person} from './person.svg';
import {ReactComponent as Find} from './find.svg';
import {ReactComponent as Calendar} from './calendar.svg';
import {ReactComponent as Close} from './close.svg';
import {ReactComponent as Phone} from './phone.svg';
import PersonIcon from './PersonIcon';


const SVGIcon = ({name, color}) => {
    
    switch (name) {
        case 'person':
            return (
                <PersonIcon color={color}/>
            );
            break;
        case 'find':
            return (
                <Find/>
            );
            break;
        case 'calendar':
            return (
                <Calendar/>
            );
            break;
        case 'close':
            return (
                <Close/>
            );
            break;
        case 'phone':
            return (
                <Phone/>
            );
            break;
    
        default:
            break;
    }
};

export default SVGIcon;