import Cookies from 'universal-cookie';

export default class Session {

    createSession(key,value,maxAge = false) {
        let cookie = new Cookies;
        let options = { path: '/'};
        if (maxAge) options['expires'] = new Date(Date.now() + maxAge);
        cookie.set(key, value, options);
    }

    getSession(key) {
        let cookie = new Cookies;
        return cookie.get(key);
    }

    removeSession(key) {
        let cookie = new Cookies;
        cookie.remove(key, { path: '/' });
    }

}

