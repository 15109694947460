import React from 'react';
import styles from './BigButton.module.css'

const BigButton = ({title, onClick, active}) => {
    return (
        <div className={styles.container + ' ' + (active ? styles.active : '')} onClick={onClick}>
            {title}
        </div>
    );
};

export default BigButton;