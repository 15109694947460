import React from 'react';
import styles from './Branch.module.css'

const Branch = ({ name, address, onClick, active }) => {

    if (address && address.split(',').length > 0) {
        address = Number.isInteger(+address.split(',')[0]) ? address.split(',').slice(1).join().trim() : address;
    }

    return (
        <div className={styles.branch__container + ' ' + (active ? styles.active : '')} onClick={onClick}>
            <div className={styles.branch__name}>{name}</div>
            <div className={styles.branch__address}>{address}</div>
        </div>
    );
};

export default Branch;