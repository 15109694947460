import React, { useContext, useEffect, useState } from 'react';
import styles from './DoctorAndServiceSearch.module.css'
import Search from './UI/search/Search';
import Specializations from './specializations/Specializations';
import DoctorsSearch from './doctorSearch/DoctorsSearch';
import ServicesSearch from './servicesSearch/ServicesSearch';
import { Context } from '../../context';
import WebApi from '../../helpers/WebApi';

const DoctorAndServiceSearch = () => {

  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){
  //   window.childDoctors = require('../../doctors.json');
  // }  

  let childDoctors = window.childDoctors;

  const {data, setData} = useContext(Context);
  const [searchText, setSearchText] = useState(data.searchText || '');
  const [doctors, setDoctors] = useState([]);
  const [childFilteredDoctors, setChildFilteredDoctors] = useState([]);

  useEffect(() => {
      setData({...data, searchText: searchText})
  }, [searchText])

  useEffect(() => {
    if(data.isAdult != 'РЕБЕНОК'){
      setChildFilteredDoctors(doctors);
      return;
    }

    if(childDoctors && childDoctors.length != 0){

      const tempDoctors = doctors.filter((doctor) => {        
        if(!childDoctors.some((childDoc) => childDoc.toLowerCase() == doctor?.fullName?.toLowerCase())){
          return false;
        } else {
          return true;                
        }        
      });

      setChildFilteredDoctors(tempDoctors);
    }
  }, [data])

  useEffect(() => {
      let web_api = new WebApi;
  
      web_api.getDoctors({...data, serviceId: null}, (res) => {
        if (res != 'error') {
          setDoctors(res);
          setChildFilteredDoctors(res);
        } else {
          console.log(res)
        }
      });
  }, [])
  
  return (
      <div className={styles.doctorAndService}>
          {!data.isAdult &&
              <div className={styles.accessor}></div>
          }
          <div className={styles.doctorAndService__title}>Выберите направление или специалиста</div>
          <Search searchText={searchText} setSearchText={setSearchText}/>
          <Specializations setSearchText={setSearchText} isActive={searchText.length == 0} doctors={childFilteredDoctors}/>
          <ServicesSearch searchText={searchText.toLowerCase()}/>
          <DoctorsSearch searchText={searchText.toLowerCase()} doctors={childFilteredDoctors}/>
      </div>
  );
};

export default DoctorAndServiceSearch;