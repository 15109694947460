import React, { useContext, useEffect, useState } from 'react';
import styles from './DoctorTime.module.css'
import moment from 'moment';
import 'moment/locale/ru'
import { Context } from '../../../context';
import useSnackbar from '../../../hooks/useSnackbar';
import MySnackbar from '../../MySnackbar';


const DoctorTime = ({ doctor, selectedDay, dayPart, selectedService }) => {
    let config = window.config;

    const { data, setData } = useContext(Context);
    const snack = useSnackbar(false, 'Ошибка');

    const [freeTimes, setFreeTimes] = useState([]);

    function filterTimes() {
        let times = doctor.freeTimes.filter(freeTime => freeTime.date.split('T')[0] === moment(selectedDay).toISOString(true).split('T')[0]);

        times = times.filter((time) => {
            if (!time.isFreeDocTime) {
                return false;
            }

            if (dayPart != null) {
                const hour = Number(time.timeBegin.slice(0, -6).split(':')[0]);

                if (dayPart == 0 && hour < 12) {
                    return true;
                } else if (dayPart == 1 && hour >= 12 && hour < 18) {
                    return true;
                } else if (dayPart == 2 && hour >= 18) {
                    return true;
                } else {
                    return false;
                }
            }

            return true;
        });

        setFreeTimes(times);

        console.log(times)
    }

    useEffect(() => {
        filterTimes()
    }, [selectedDay, dayPart])

    function selectDoctorTime(datetime) {
        if (config.serviceRequired && !selectedService) {
            snack.handleChange(true, 'Выберите услугу!');
            return;
        }

        setData({
            ...data,
            doctor: doctor,
            doctorName: doctor.userName,
            timeBegin: datetime.timeBegin,
            timeEnd: datetime.timeEnd,
            date: datetime.date,
            cabinetId: datetime.cabinetId,
            doctorSpecializationId: doctor.id,
            serviceId: selectedService?.value,
            serviceName: selectedService?.label,
            servicePrice: selectedService?.price
        });
    }

    function getCustomMonth(month) {
        if (month.slice(-1) == 'т') {
            return month.toUpperCase() + 'A';
        }
        return month.toUpperCase().slice(0, -1) + 'Я';
    }


    return (
        <>
            <MySnackbar {...snack} />
            {freeTimes.length > 0 &&

                <div className={styles.container}>

                    <div className={styles.container__header}>
                        <div className={styles.container__header_photo}>
                            {doctor.photoUrl != null ?
                                (<img src={doctor.photoUrl} />) :
                                (<img src={require('../../../img/doctor.png')} />)
                            }
                        </div>
                        <div className={styles.container__header_info}>
                            <div className={styles.container__header_info__username}>{doctor.userName}</div>
                        </div>
                    </div>

                    <div className={styles.container__header_info__specializations}>
                        {doctor.specializations.map((e, i) => {
                            return `${e.name}${(doctor.specializations.length > 1 && i != Number(doctor.specializations.length - 1)) ? ', ' : ''}`;
                        })}
                    </div>
                    {selectedService?.price && config.show_services_price &&
                        <div className={styles.container__header_info__specializations}>
                            Стоимость приема - {selectedService.price} {config.currency}
                        </div>
                    }

                    <div className={styles.container__date}>
                        {moment(selectedDay).format('dddd')}, {moment(selectedDay).date()} {getCustomMonth(moment(selectedDay).format('MMMM'))}
                    </div>
                    <div className={styles.container__times}>
                        {freeTimes.map((time, i) =>
                            time.isFreeDocTime &&
                            <div key={i} onClick={() => selectDoctorTime(time)}>{(time.timeBegin.slice(0, -3))}</div>
                        )}
                    </div>
                </div>
            }
        </>

    );
};

export default DoctorTime;