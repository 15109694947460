import React, { useContext, useEffect, useState } from 'react';
import styles from './DoctorsSearch.module.css'
import { Context } from '../../../context';
import DoctorModal from '../../doctorModal/DoctorModal';
import DoctorSearchWithFreeDays from './doctor/DoctorSearchWithFreeDays';

const DoctorsSearch = ({searchText, doctors}) => {
    
    const [filteredDoctors, setFilteredDoctors] = useState([]);
    const {data, setData} = useContext(Context);

    //modal
    const [isModal, setModal] = useState(false);
    const [selectedDoctor, setSelectedDoctor] = useState(null);

    function viewDoctorInfo(doctor){   
        setSelectedDoctor(doctor);
        setModal(true);
    }

    useEffect(() => {
        if(filteredDoctors.length > 0 || searchText.length > 0){
            doctorsFilter();
        }
    }, [data])

    useEffect(() => {
        if(searchText.length > 0){
            doctorsFilter();
        } else {
            setFilteredDoctors([])
        }
    }, [searchText, doctors])

    function doctorsFilter(){

        setFilteredDoctors(
            doctors.filter((doctor) => {
                if(doctor?.fullName?.toLowerCase().includes(searchText)){
                    return true;
                }

                const findSpecialization = doctor.specializations.some((specialization) => {

                    if(specialization.name.toLowerCase().includes(searchText)){
                        return true;
                    }
                });

                return findSpecialization;                
            })
        )
    }    

    return (
        <div>
            {selectedDoctor &&
                <DoctorModal
                    doctor={selectedDoctor}
                    isVisible={isModal}
                    onClose={() => {setModal(false)}}
                />
            }
            {filteredDoctors.length != 0 ?
                <div className={styles.doctors}>
                    <div className={styles.doctors__text}>Врачи</div>

                    <div className={styles.doctors__container}>

                        {data.isMobile &&
                            <div>
                                {filteredDoctors.length > 0 && filteredDoctors.map((doctor, i) => 
                                    <DoctorSearchWithFreeDays
                                        key={doctor.id} 
                                        doctor={doctor} 
                                        onClick={() => viewDoctorInfo(doctor)}
                                        searchText={searchText}
                                    />
                                )}     
                            </div>
                        }


                        {!data.isMobile &&
                            <>
                                {/* три колонки */}
                                <div>
                                    {filteredDoctors.length > 0 && filteredDoctors.map((doctor, i) => 
                                        (i == 0 || i % 3 == 0) && 
                                        <DoctorSearchWithFreeDays
                                            key={doctor.id} 
                                            doctor={doctor} 
                                            onClick={() => viewDoctorInfo(doctor)}
                                            searchText={searchText}
                                        />
                                    )}     
                                </div>
                                <div>
                                    {filteredDoctors.length > 0 && filteredDoctors.map((doctor, i) => 
                                        ((i+1) % 3 == 0) && 
                                        <DoctorSearchWithFreeDays
                                            key={doctor.id} 
                                            doctor={doctor} 
                                            onClick={() => viewDoctorInfo(doctor)}
                                            searchText={searchText}
                                        />
                                    )}     
                                </div>
                                <div>
                                    {filteredDoctors.length > 0 && filteredDoctors.map((doctor, i) => 
                                        ((i-1) % 3 == 0) && 
                                        <DoctorSearchWithFreeDays
                                            key={doctor.id} 
                                            doctor={doctor} 
                                            onClick={() => viewDoctorInfo(doctor)}
                                            searchText={searchText}
                                        />
                                    )}     
                                </div>
                            </>
                        }
                    </div>
                </div>
                :
                ''
            }
        </div>
        
    );
};

export default DoctorsSearch;