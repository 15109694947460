import React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MySnackbar = ({ message, open, onClose, severity }) => {

  return (
    <>
      <Snackbar
        open={open}
        onClose={onClose}
        autoHideDuration={4000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert severity={severity} onClose={onClose} sx={{ width: '100%' }}>{message}</Alert>
      </Snackbar>
    </>
  );
};

export default MySnackbar;
