import React from 'react';
import styles from './Button.module.css'

const Button = ({onClick = () => {}, active, children, radius = 10, disabled = false, onMouseEnter = () => {}, onMouseLeave = () => {}}) => {
    return (
        <div 
            className={styles.container + ' ' + (styles[`radius${radius}`]) + ' ' + (!active ? styles.active : '') + ' ' + (disabled ? styles.disabled : '')} 
            onClick={() => !disabled && onClick()}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
                {children}
        </div>
    );
};

export default Button;