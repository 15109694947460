import React, {useContext, useEffect, useState} from 'react';
import styles from './DoctorModal.module.css'
import Button from '../UI/button/Button';
import WebApi from '../../helpers/WebApi';
import { Context } from '../../context';
import SVGIcon from '../UI/SVGIcons/SVGIcon';

const DoctorModal = ({isVisible = false, doctor, onClose }) => {
   
    const keydownHandler = ({ key }) => {
      switch (key) {
        case 'Escape':
          onClose();
          break;
        default:
      }
    };
  
    useEffect(() => {
      document.addEventListener('keydown', keydownHandler);
      return () => document.removeEventListener('keydown', keydownHandler);
    });

    let config = window.config;

    const {data, setData} = useContext(Context);

    const [services, setServices] = useState([]);

    useEffect(() => {
      let web_api = new WebApi;
  
      web_api.getServices({...data, userId: doctor.id}, (res) => {
        if (res != 'error') {
          setServices(res.filter((service) => service.price != null));
        } else {
          console.log(res)
        }
      });
    }, [doctor])


    function selectDoctor(value){
        setData({...data, userId: value});
    }   

    function selectService(serviceId, name, price){
      setData({...data, userId: doctor.id, serviceId: serviceId, serviceName: name, servicePrice: price});
    } 

  
    return !isVisible ? null : (
      <div className={styles.modal}  onClick={onClose}>
        <div className={styles.modal__container} onClick={(e) => e.stopPropagation()}>
          <div className={styles.modal__container_title}>
            <h3>О враче</h3>
            <div className={styles.modal__close_btn}>
              <Button active={true} onClick={onClose}>
                <SVGIcon name={'close'}/>
              </Button>
            </div>
          </div>
          <div className={styles.modal__header}>
              {doctor.photoUrl 
                  ? <img className={styles.modal__image} src={doctor.photoUrl}/>     
                  : <img className={styles.modal__image} src={require('../../img/doctor.png')}/>    
              }

              <div>
                  <div className={styles.modal__name}>{doctor.fullName}</div>
                  <div className={styles.modal__specializations}>{doctor.specializations.map((e, i) => ' ' + e.name + (doctor.specializations.length > 1 && i != Number(doctor.specializations.length - 1) ? ',' : ''))}</div>
                  <Button onClick={() => selectDoctor(doctor.id)}  active={false}><div className={styles.appointment_button}>ЗАПИСАТЬСЯ НА ПРИЕМ</div></Button>
              </div>

          </div>

            {doctor.description && <h3>Информация</h3>}
            <div className={styles.modal__info}>{doctor.description}</div>

            <h3>Услуги</h3>
            {services.length > 0 && services.map((service) => 

              <div key={service.name} className={styles.modal_doctor__service}>
                <div className={styles.modal_doctor__service_name}>{service.name}</div>
                <div className={styles.modal_doctor__service_price}>
                  {config.show_services_price && <div className={styles.modal_doctor__service_price_text}>{service.price} {config.currency}</div>}
                  <Button onClick={() => selectService(service.id, service.name, service.price)}  active={false}><div className={styles.appointment_button}>ЗАПИСАТЬСЯ</div></Button>
                </div>

              </div>
            
            )}



        </div>
        
      </div>
    );
  };

  export default DoctorModal;