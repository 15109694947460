import { useState } from "react";

export default function useSnackbar(initialOpen, initialMessage, initialSeverity = 'warning'){
  const [open, setOpen] = useState(initialOpen);
  const [message, setMessage] = useState(initialMessage);
  const [severity, setSeverity] = useState(initialSeverity); //error, warning, info, success


  const onClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }

  const handleChange = (open, message, severity = 'warning') => {
    setOpen(open);
    setMessage(message);
    setSeverity(severity)
  }

  return {
    open, onClose, message, severity, handleChange
  }

}