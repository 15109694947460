import React, { useContext, useEffect, useState } from 'react';
import styles from './DoctorSearch.module.css'
import { Context } from '../../../../context';
import Button from '../../../UI/button/Button';
import moment from 'moment';
import WebApi from '../../../../helpers/WebApi';

const DoctorSearchWithFreeDays = ({ doctor, onClick, searchText }) => {

    const { data, setData } = useContext(Context);
    const [dates, setDates] = useState([]);
    const [loader, setLoader] = useState(true);

    function selectDoctor(date) {
        setData({ ...data, userId: doctor.id, selectedDay: date || null });
    }

    useEffect(() => {
        let web_api = new WebApi;

        web_api.getDoctorsFreeTime({
            ...data,
            fromDate: moment().startOf('isoDay').toISOString(true),
            tillDate: moment().add(30, 'days').toISOString(true),
            userId: doctor.id
        }, (res) => {
            if (res != 'error') {
                setLoader(false);

                let set = new Set();

                for (const time of res[0].freeTimes) {
                    if (time.isFreeDocTime) {
                        set.add(time.date.split('T')[0]);
                    }
                }

                setDates(Array.from(set, (date) => new Date(date)).sort(function (a, b) {
                    return new Date(a) - new Date(b);
                }).slice(0, 18));

            } else {
                console.log(res)
            }
        });
    }, [])

    function isSearchedDoctor() {
        if (searchText.split(' ').length > 1) {
            if (doctor?.fullName?.toLowerCase().includes(searchText)) {
                return true;
            }
        }
    }

    function getCustomMonth(month) {
        if (month.length > 4) {
            return month.slice(0, 3).toUpperCase() + '.';
        }

        if (month.slice(-1) == 'т') {
            return month.toUpperCase() + 'A';
        }

        return month.toUpperCase().slice(0, -1) + 'Я';
    }

    // return (dates.length > 0 || loader || isSearchedDoctor()) &&
    return <div className={styles.doctorSearch__container}>
        <div className={styles.doctorSearch__container_info}>
            <div className={styles.doctorSearch__photo}>
                {doctor.photoUrl != null ?
                    (<img src={doctor.photoUrl} />) :
                    (<img src={require('../../../../img/doctor.png')} />)
                }
            </div>
            <div>
                <div onClick={onClick} >{doctor.fullName}</div>
                <div className={styles.doctor__specializations}>
                    {doctor.specializations.map((e, i) => {
                        return ' ' + e.name + (doctor.specializations.length > 1 && i != Number(doctor.specializations.length - 1) ? ',' : '');
                    })}
                </div>
            </div>
        </div>

        {(dates.length == 0 && !loader) &&
            <div className={styles.doctorSearch__container_notime}>На ближайшие 30 дней все занято. Хотите записаться на более поздние даты?</div>

        }
        <div className={styles.button}>
            <Button onClick={() => selectDoctor()} active={true} radius={15}>
                <div className={styles.doctorSearch__container_button}>Записаться на прием</div>
            </Button>
        </div>


        {(dates.length > 0 || loader) &&
            <div className={styles.container__times}>
                {dates.length > 0 && dates.map(date => (
                    <div key={date} onClick={() => selectDoctor(date)}>
                        {moment(date).date()} {getCustomMonth(moment(date).format('MMMM'))}
                    </div>
                ))
                }
                {loader &&
                    <>
                        <div className={styles.skeleton}> <div></div></div>
                        <div className={styles.skeleton}> <div></div></div>
                        <div className={styles.skeleton}> <div></div></div>
                    </>
                }
            </div>
        }
    </div>
};

export default DoctorSearchWithFreeDays;