import React, { useEffect, useState } from 'react';
import styles from '../../appointmentTypes/AppointmentTypes.module.css'
import WebApi from '../../../helpers/WebApi';
import BigButton from '../../UI/bigButton/BigButton';

const ListAppointmentTypes = ({onClick, selected}) => {

    const [types, setTypes] = useState([]);

    useEffect(() => {
        let web_api = new WebApi;
    
        web_api.getAppointmentTypes((res) => {
          if (res != 'error') {
            setTypes(res);
          } else {
            console.log(res)
          }
        });
    }, [])

    return (
        <div className={styles.types}>
            <div className={styles.types__title}>Выберите тип приема</div>
            <div className={styles.types__container}>
                {types && types.length > 0 && types.map((type) => 
                    <BigButton key={type.key} title={type.value.toUpperCase()} onClick={() => onClick(type.key)} active={selected == type.key}/>                
                )}             
            </div>
        </div>
    );
};

export default ListAppointmentTypes;