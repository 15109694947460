import React, { useContext } from 'react';
import Branch from './branch/Branch';
import styles from './Branches.module.css'
import { Context } from '../../context';

const Branches = () => {

    const {data, setData, branches} = useContext(Context);

    function selectBranch(value){
        setData({...data, clinicBranchId: value.id, clinicBranchName: value.fullName});
    }    

    return (
        <div className={styles.branches}>
            <div className={styles.branch__title}>Выберите филиал</div>
            <div className={styles.branches__container}>
                {branches && branches.length > 0 && branches.map((branch) => 
                    <Branch 
                        key={branch.id} 
                        name={branch.name} 
                        address={branch.addressLocation}
                        onClick={() => selectBranch(branch)}
                        active={data.clinicBranchId == branch.id}/>
                )}                
            </div>
        </div>
    );
};

export default Branches;