import React, { useEffect, useState } from 'react';
import WebApi from '../../helpers/WebApi';
import styles from './LinkListServices.module.css'
import ListBranches from './branches/ListBranches';
import ListAppointmentTypes from './appointmentTypes/ListAppointmentTypes';

const LinkListServices = () => {

    let config = window.config;

    const [services, setServices] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedAppointmentType, setSelectedAppointmentType] = useState(null);

    useEffect(() => {
        if(selectedAppointmentType == null || selectedBranch == null) return;

        let web_api = new WebApi;
    
        web_api.getServices({clinicBranchId: selectedBranch?.id, serviceType: selectedAppointmentType}, (res) => {
          if (res != 'error') {
            setServices(res.filter((service) => service.price != null));
          } else {
            console.log(res)
          }
        });
    }, [selectedBranch, selectedAppointmentType])


    function generateLink(service){
        const serviceId = service.id;
        const currentProtocol = document.location.protocol;
        const currentHost = document.location.host;
        const serviceGet = '?service=' + serviceId;
        let folder;
        if (config.batch != '/') { folder = config.batch } else { folder = '' }
        let linkService = currentProtocol + '//' + currentHost + folder + serviceGet;
        linkService += `&branch=${selectedBranch?.id}`
        selectedAppointmentType != null && (linkService += `&type=${selectedAppointmentType}`);
        return linkService;
    }

    function copyLink(link){
        navigator.clipboard.writeText(link);
        alert('Ссылка скопирована в буфер обмена');
    }


    return (
        <div>
            <ListAppointmentTypes selected={selectedAppointmentType} onClick={setSelectedAppointmentType}/>
            <ListBranches selectedBranch={selectedBranch} onClick={setSelectedBranch}/>
            <div>
                {services.length ? <> {services.map((service, key) =>
                    <div className={styles.service_item} key={key} onClick={() => copyLink(generateLink(service))}> 
                        <div>{service.name}</div>
                    </div>
                )}</> : <>
                    {selectedBranch ? 
                        'услуг нет'
                        : 'выберите филиал'
                    }
                </>}
            </div>

        </div>
    );
};

export default LinkListServices;