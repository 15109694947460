import React, { useContext } from 'react';
import styles from './Tabs.module.css'
import Tab from './tab/Tab';
import { Context } from '../../context';

const Tabs = ({steps, activeStep, changeStep}) => {
    const {data} = useContext(Context);

    if(data.isOneBranchAndOneType && activeStep == 1){
        changeStep(2);
    }
    
    return (
        <>
            <div className={styles.tabs__container}>
                {steps.map((step, index) => 
                    <Tab 
                        key={index} 
                        title={step.title} 
                        number={data.isOneBranchAndOneType ? index : index + 1} 
                        active={index + 1 == activeStep} 
                        onClick={() => {
                            if(data.isOneBranchAndOneType && activeStep == index + 1){
                                console.log(index, activeStep)
                            } else {
                                changeStep(data.isOneBranchAndOneType ? index : index + 1)
                            }
                        }} 
                        accessible={step.accessible} 
                        visible={data.isOneBranchAndOneType && index == 0}
                    />)
                }
            </div>


            {data.isOneBranchAndOneType && activeStep != 2 && <div className={styles.btn_back} onClick={() => changeStep(activeStep - 1)}> <span className={styles.arrow}>&lt;</span> назад</div>}
            
            {!data.isOneBranchAndOneType && activeStep != 1 && <div className={styles.btn_back} onClick={() => changeStep(activeStep - 1)}> <span className={styles.arrow}>&lt;</span> назад</div>}
        </>
    );
};

export default Tabs;