import React, { useEffect, useState } from 'react';
import styles from './Search.module.css'
import SVGIcon from '../../../UI/SVGIcons/SVGIcon';

const Search = ({searchText, setSearchText}) => {

    const [inputValue, setInputValue] = useState(searchText);

    useEffect(() => {
        setInputValue(searchText);
    }, [searchText])

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setSearchText(inputValue);
        }, 900);
        return () => clearTimeout(timeoutId);
    }, [inputValue]);

    return (
        <div 
            className={styles.search__container} 
            onClick={()=>{
                const input = document.getElementById("textField");
                input.focus()
            }}
        >
            <div className={styles.search__container_icon}>
                <SVGIcon name={'find'}/>
            </div>
            <input 
                id='textField'
                placeholder="ФИО Специальность Название услуги" 
                value={inputValue}
                onChange={handleInputChange}
            />
        </div>
    );
};

export default Search;